const configService = {
  apiAlegraConfig: 'https://config.alegra.com/api/v1'
}

configService.apiUrl = () => {
  if (process.env.VUE_APP_ENVIROMENT === 'production') {
    return 'https://shops-api.alegra.com/v1'
  } else {
    return 'https://vyarryeuv3.execute-api.us-east-1.amazonaws.com/test'
  }
}

configService.domainUrl = () => {
  if (process.env.VUE_APP_ENVIROMENT === 'production') {
    return 'alegratienda.com'
  } else {
    return 'almost-alegrashop.com'
  }
}

export default configService
