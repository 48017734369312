var render = function () {
  var _vm$v$billingInfo$ide;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shoulShowIdentificationForm ? _c('div', {
    staticClass: "columns"
  }, [_vm.v.billingInfo.identificationType ? _c('div', {
    staticClass: "column is-5"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v(" Tipo de identificación " + _vm._s(_vm.isIdentificationTypeRequired ? '*' : '') + " ")]), _c('div', {
    staticClass: "select control is-fullwidth"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.v.billingInfo.identificationType.$model,
      expression: "v.billingInfo.identificationType.$model",
      modifiers: {
        "trim": true
      }
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.v.billingInfo.identificationType, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.identificationTypes, function (type, indexOption) {
    return _c('option', {
      key: indexOption,
      domProps: {
        "value": type
      }
    }, [_vm._v(" " + _vm._s(type.value) + " ")]);
  }), 0)]), _vm.showIdentificationTypeMessage ? _c('div', [_c('p', {
    staticClass: "help is-danger"
  }, [_vm._v("Selecciona un tipo de identificación")])]) : _vm._e()])]) : _vm._e(), _vm.v.billingInfo.identification ? _c('div', {
    staticClass: "column is-7"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v("Número de identificación " + _vm._s(_vm.isIdentificationRequired ? '*' : ''))]), _c('div', {
    staticClass: "control"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.v.billingInfo.identification.$model,
      expression: "v.billingInfo.identification.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "input",
    class: {
      error: _vm.shouldShowIdentificationError
    },
    attrs: {
      "type": "text",
      "placeholder": "Tu número de identificación",
      "disabled": !((_vm$v$billingInfo$ide = _vm.v.billingInfo.identificationType) !== null && _vm$v$billingInfo$ide !== void 0 && _vm$v$billingInfo$ide.$model)
    },
    domProps: {
      "value": _vm.v.billingInfo.identification.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.v.billingInfo.identification, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.showIdentificationMessage ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa tu número de identificación ")]) : !_vm.v.billingInfo.identification.numeric ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa un número de identificación válido ")]) : _vm.showInvalidCfFormat ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" La cédula física debe ser un valor numérico de 9 dígitos, sin ceros al inicio y sin guiones ")]) : !_vm.isIdentificationValid ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" Ingresa un formato de identificación válido ")]) : _vm._e()])]) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }