/* Validations for Vuelidate */
import {
  IDENTIFICATION_PERU_DNI_FORMAT_LENGTH,
  IDENTIFICATION_DOM_RNC_FORMAT_LENGTH,
  IDENTIFICATION_DOM_CED_FORMAT_LENGTH,
  IDENTIFICATION_CRI_CF_FORMAT_LENGTH,
  IDENTIFICATION_CRI_CJ_FORMAT_LENGTH,
  IDENTIFICATION_CRI_NITE_FORMAT_LENGTH,
  IDENTIFICATION_CRI_PE_FORMAT_LENGTH,
  IDENTIFICATION_CRI_DIMEX_FORMAT_MIN_LENGTH,
  IDENTIFICATION_CRI_DIMEX_FORMAT_MAX_LENGTH
} from './constants'

export const isValidDNIPeru = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'DNI') return true

  if (value?.length !== IDENTIFICATION_PERU_DNI_FORMAT_LENGTH) return false

  return true
}

export const isValidRncDom = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'RNC') return true

  if (value?.length !== IDENTIFICATION_DOM_RNC_FORMAT_LENGTH) return false

  return true
}

export const isValidCedDom = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'CED') return true

  if (value?.length !== IDENTIFICATION_DOM_CED_FORMAT_LENGTH) return false

  return true
}

export const isValidCfCri = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'CF') return true

  if (value?.length !== IDENTIFICATION_CRI_CF_FORMAT_LENGTH) return false

  // Verificar que no contenga guiones ni otros caracteres especiales
  if (/[^0-9]/.test(value)) return false

  // Verificar formato numérico sin ceros al inicio
  const numericPattern = /^[1-9]\d{8}$/
  if (!numericPattern.test(value)) return false

  return true
}

export const isValidCjCri = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'CJ') return true

  if (value?.length !== IDENTIFICATION_CRI_CJ_FORMAT_LENGTH) return false

  return true
}

export const isValidDimexCri = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'DIMEX') return true

  if (
    value?.length < IDENTIFICATION_CRI_DIMEX_FORMAT_MIN_LENGTH ||
    value?.length > IDENTIFICATION_CRI_DIMEX_FORMAT_MAX_LENGTH
  ) {
    return false
  }

  return true
}

export const isValidNiteCri = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'NITE') return true

  if (value?.length !== IDENTIFICATION_CRI_NITE_FORMAT_LENGTH) return false

  return true
}
// Identificación extranjero
export const isValidPeCri = (value, context) => {
  const identificationTypeValue = context.identificationType?.key

  if (identificationTypeValue !== 'PE') return true

  if (value?.length > IDENTIFICATION_CRI_PE_FORMAT_LENGTH) return false

  return true
}
