import productService from '@/services/product'
import store from '@/store'

const actions = {
  getProducts() {
    const idStore = store.get('shop/shop.id')
    const query = store.get('products/searchQuery')
    const page = store.get('products/searchPage')
    const metadata = store.get('products/metadata')
    const category = store.get('categories/categorieSelected')
    const productsCountToShow = store.get('products/productsCountToShow')
    const timesToSearch = store.get('products/timesToSearch')
    const limit = 30
    const start = page * limit

    const data = {
      limit,
      start,
      idCategory: category ? category.id : null,
      query: query || null,
      metadata
    }

    if (query) store.set('products/searchingProducts', true)
    store.set('products/loadingProducts', true)
    return productService
      .getProducts(idStore, data)
      .then(res => {
        if (query) store.set('products/searchingProducts', false)
        store.set('products/loadingProducts', false)
        if (res.items) {
          let items = store.get('products/products') || []
          items = items.concat(res.items)

          if (category?.id) {
            items = items.filter(item => parseInt(item.category?.id) === parseInt(category.id))
          }

          store.set('products/products', items)
          if (res.metadata.itemsCount) {
            if (timesToSearch === 0) {
              store.set('products/timesToSearch', Math.floor(res.metadata.totalCount / 30))
            }

            if (productsCountToShow === 0) {
              store.set('products/productsCountToShow', res.metadata.itemsCount)
            } else {
              store.set('products/productsCountToShow', productsCountToShow + res.metadata.itemsCount)
            }
          }
          if (res.metadata && (items.length < res.metadata.totalCount || res.metadata.lastKey)) {
            store.set('products/availableMoreProducts', true)
          } else {
            store.set('products/availableMoreProducts', false)
          }

          if (res.metadata.itemsCount) {
            if (page >= store.get('products/timesToSearch')) {
              store.set('products/availableMoreProducts', false)
            }
          }

          if (res.metadata) {
            store.set('products/metadata', res.metadata)
          } else {
            store.set('products/metadata', null)
          }
          return res.items
        }
        return false
      })
      .catch(e => {
        if (query) store.set('products/searchingProducts', false)
        store.set('products/loadingProducts', false)
      })
  },
  getFeaturedProducts() {
    const idStore = store.get('shop/shop.id')

    const data = {
      start: 0,
      limit: 30,
      featured: true
    }

    return productService.getProducts(idStore, data).then(res => {
      if (res.items) {
        store.set('products/featuredProducts', res.items)
        return res.items
      }
      return false
    })
  },
  getProductById(context, { idProduct, shouldSetProduct = true }) {
    return new Promise(async (resolve, reject) => {
      try {
        const idStore = store.get('shop/shop.id')
        const { item } = await productService.getById(idStore, idProduct)

        if (shouldSetProduct) {
          store.set('products/productDetail', item)
        }

        resolve(item)
      } catch (e) {
        reject(e)
      }
    })
  },
  getInventoryAvailability({ dispatch }, itemsIDs) {
    return new Promise(async (resolve, reject) => {
      try {
        const idStore = store.get('shop/shop.id')
        const { items } = await productService.getInventoryAvailability(idStore, itemsIDs)

        const itemsInventory = items.map(item => ({
          id: item.id.toString(),
          inventory: item.inventory,
          inventorySettings: item.inventorySettings !== null ? item.inventorySettings : null
        }))

        if (itemsInventory.length) {
          setTimeout(() => {
            store.set('products/productsInCartInventory', itemsInventory)
          }, 0)
        }

        resolve(itemsInventory)
      } catch (e) {
        reject(e)
      }
    })
  },
  async checkInventory({ dispatch }, products) {
    const productsIDs = products.map(product => product.id).toString()
    const productsInventory = await dispatch('getInventoryAvailability', productsIDs)
    const someProductHasInsufficientStock = productsInventory.some(productInventory => {
      const productToCheckInventory = products.find(product => product.id.toString() === productInventory.id.toString())

      // si no está sincronizado con AC, el back retorna null, sin importar el tipo de ítem (ver models/Item.overridePropertiesFromItemStore() -> Item.toPublicJson())
      // para no cortar las ventas y salir del error de loading infinito, se agrega este check provisorio
      if (productInventory.inventorySettings === null) {
        return false
      }

      const shouldCheckForStock =
        productInventory.inventory && !productInventory.inventorySettings?.sellWithoutStock

      const hasInsufficientStock =
        productInventory.inventorySettings?.quantity < productToCheckInventory.quantity ||
        productInventory.inventorySettings?.quantity <= 0

      return shouldCheckForStock && hasInsufficientStock
    })

    return someProductHasInsufficientStock
  }
}

export default actions
