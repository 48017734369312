var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.v.address ? _c('div', [_c('div', {
    staticClass: "location-fields columns"
  }, [_c('div', {
    staticClass: "column is-6"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.locationLabelsText.department) + " *")]), _c('div', {
    staticClass: "select control"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.v.address.department.$model,
      expression: "v.address.department.$model",
      modifiers: {
        "trim": true
      }
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.v.address.department, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.departments, function (department, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": department
      }
    }, [_vm._v(" " + _vm._s(department.value) + " ")]);
  }), 0)]), _vm.v.address.department.$dirty ? _c('div', [!_vm.v.address.department.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" " + _vm._s(_vm.locationLabelsText.departmentError) + " ")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "column is-6"
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.locationLabelsText.city) + " *")]), _c('div', {
    staticClass: "select control"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.v.address.city.$model,
      expression: "v.address.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    attrs: {
      "disabled": !_vm.v.address.department.$model
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.v.address.city, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.cities, function (city, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": city
      }
    }, [_vm.applicationVersion === _vm.VERSIONS.PANAMA ? _c('span', [_vm._v(_vm._s(((city.parentValue) + ", " + (city.value))))]) : _vm._e(), _vm.applicationVersion === _vm.VERSIONS.COSTA_RICA ? _c('span', [_vm._v(_vm._s(((city.parentValue) + ", " + (city.value))))]) : _c('span', [_vm._v(_vm._s(city.value))])]);
  }), 0)]), _vm.v.address.city.$dirty ? _c('div', [!_vm.v.address.city.required ? _c('p', {
    staticClass: "help is-danger"
  }, [_vm._v(" " + _vm._s(_vm.locationLabelsText.cityError) + " ")]) : _vm._e()]) : _vm._e()])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }